
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import {
  SermonApi,
  SermonRequestOptions,
  SermonSortOptions,
} from '~/apiclient/apisermons'
import { Sermon } from '~/models/sermon'
import {
  getStrValuesFromEnum,
  SermonDisplay,
  SermonMetadataExtendedInfo,
} from '~/assets/ts/enums'
import {
  GetSermonSortFromQs,
  GetSermonVideoFromQs,
  SermonQuery,
  SermonSortQsPair,
} from '~/components/sort/SermonsElement.vue'
import { Broadcaster } from '~/models/broadcaster'
import { Series } from '~/models/series'
import { PromiseAll, wait } from '~/assets/ts/utils/misc'
import { getListWithPlaceholders } from '~/assets/ts/utils/lists'
import {
  FiltersToSermonRequestOptions,
  GetSermonFiltersFromQs,
  SermonFilterCategories,
  SermonFilterCategoryTitle,
  SermonFilterSelection,
  SermonFiltersQsPairs,
  updateQs,
} from '~/assets/ts/utils/params'
import { Speaker } from '~/models/speaker'
import { ValuesMatch } from '~/assets/ts/utils/validation'
import ScrollList from '~/components/_general/ScrollList.vue'
import SermonElement from '~/components/_general/SermonElement.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import SiteSermonListHeader from '~/components/site/SermonListHeader.vue'
import { ListHeaderWrapperProps } from '~/components/_general/ListHeaderWrapper.vue'
import SiteButton from '~/components/site/Button.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import IconCircleButton from '~/components/_general/IconCircleButton.vue'
import {
  DurationFilterTitle,
  MediaFilterTitle,
  ParseDurationFilter,
  ParseMediaFilter,
} from '~/apiclient/apifilters'

export const SiteSermonFilteredListProps = {
  sermonOptions: {
    type: Object as PropType<SermonRequestOptions>,
    required: true,
  },
  maxExtendedInfo: {
    type: Number as PropType<SermonMetadataExtendedInfo>,
    default: SermonMetadataExtendedInfo.Broadcaster,
  },
  removeSermonIds: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  noResultsText: {
    type: String as PropType<TranslateResult>,
    default: '',
  },
  infinite: {
    type: Boolean,
    default: true,
  },
  display: {
    type: Number as PropType<SermonDisplay>,
    default: SermonDisplay.Extended,
  },
  placeholderCount: {
    type: Number,
    default: 5,
  },
  topBorder: {
    type: Boolean,
    default: true,
  },
  allowFiltering: {
    type: Boolean,
  },
  disableSearch: {
    type: Boolean,
  },
  disableSort: {
    type: Boolean,
  },
  storeInQs: {
    type: Boolean,
    default: true,
  },
  async: {
    type: Boolean,
  },
  showBibleText: {
    type: Boolean,
  },
  buffer: {
    type: Number,
    default: 700,
  },
  showCount: {
    type: Boolean,
    default: true,
  },
  customCountText: {
    type: String as PropType<TranslateResult>,
    default: undefined,
    validator(value: TranslateResult) {
      if (!value) return true
      return value.toString().includes('{n}')
    },
  },
  small: {
    type: Boolean,
  },
  defaultSort: {
    type: String as PropType<SermonSortOptions>,
    default: SermonSortOptions.Newest,
  },
  scrollListClasses: {
    type: String,
    default: '',
  },
  selectable: {
    type: Number,
    default: 0,
  },
  selectedSermonIDs: {
    type: Array as PropType<string[]>,
    default: () => {
      return []
    },
  },
  absoluteUrls: {
    type: Boolean,
  },
  defaultFilters: {
    type: Array as PropType<SermonFilterSelection[]>,
    default: () => [],
  },
  showDuration: {
    type: Boolean,
    default: true,
  },
  onlyComments: {
    type: Boolean,
  },
}

function getInitialFilters(
  vue: Vue,
  useQs: boolean,
  defaults: SermonFilterSelection[]
): SermonFilterSelection[] {
  const filters = useQs ? GetSermonFiltersFromQs(vue) : defaults
  if (useQs && defaults) {
    defaults.forEach((filter) => {
      const inQs = filters.find((f) => f.category === filter.category)
      if (!inQs) {
        filters.push(filter)
      }
    })
  }
  return filters
}

export default Vue.extend({
  name: 'SiteFilteredSermonList',
  components: {
    IconCircleButton,
    InlineIcon,
    SiteButton,
    SaIcon,
    SermonElement,
    ScrollList,
    SiteSermonListHeader,
  },
  props: {
    ...SiteSermonFilteredListProps,
    ...ListHeaderWrapperProps,
    noResultsText: {
      type: String as PropType<TranslateResult>,
      default(): TranslateResult {
        return this.$t('No Results')
      },
    },
    /**
     * This uses the container itself for the scrolling events, rather than the page body.
     * This is primarily used for things like modals.
     * */
    scrollSelf: {
      type: Boolean,
    },
    displaySwitcher: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const useQs = this.storeInQs && this.allowFiltering
    return {
      sermonsPojo: [] as Record<string, any>[],
      pageNum: this.sermonOptions.page ?? 1,
      totalCount: undefined as number | undefined,
      optionsReset: false,
      sort: useQs
        ? GetSermonSortFromQs(this, this.defaultSort)
        : this.defaultSort,
      filters: getInitialFilters(this, useQs, this.defaultFilters),
      sermonOptionsTimeout: 0,
      next: undefined as string | undefined,
      fetching: false,
      selfChangeQs: false, // indicates to the route query watcher that we dont need to refetch
      videoOnly: useQs ? GetSermonVideoFromQs(this) : false,
    }
  },
  async fetch() {
    if (this.async) return
    await this.getDetails()
  },
  computed: {
    showLastPlayTime(): boolean {
      return this.sort === SermonSortOptions.LastPlayed
    },
    wrapperClasses(): string {
      return this.videoGrid ? 'video-grid' : ''
    },
    videoGrid(): boolean {
      return this.chosenDisplay === SermonDisplay.VideoWrap
    },
    chosenDisplay(): SermonDisplay {
      if (this.videoOnly) return SermonDisplay.VideoWrap
      return this.display
    },
    hashtagSearch(): boolean {
      return Object.keys(this.sermonOptions).includes('hashtag')
    },
    useQs(): boolean {
      return this.storeInQs && this.allowFiltering
    },
    noResultsTextOrDefault(): TranslateResult {
      if (this.videoOnly && this.displaySwitcher) {
        return this.$t('No video sermon results')
      }
      return (
        this.noResultsText ??
        this.$t('No sermons found with the selected filters')
      )
    },
    disabledFilterCategories(): SermonFilterCategories[] {
      const allCategories = getStrValuesFromEnum(SermonFilterCategories)
      const disabled = [] as SermonFilterCategories[]
      Object.keys(this.sermonOptions).forEach((key) => {
        if (allCategories.includes(key)) {
          disabled.push(key as SermonFilterCategories)
        }
      })
      return disabled
    },
    filteredAndSortedOptions(): SermonRequestOptions {
      return {
        ...{
          requireVideo: this.videoOnly ? true : undefined,
          requireAudio: false,
          sortBy: this.disableSort ? undefined : this.sort,
        },
        ...FiltersToSermonRequestOptions(this.filters),
        ...this.sermonOptions,
      }
    },
    maxListLength(): number | undefined {
      const pageSize = this.filteredAndSortedOptions?.pageSize
      return !this.infinite && pageSize ? pageSize : undefined
    },
    sermons(): Sermon[] | undefined {
      const sermons = this.sermonsPojo
        .map((s: Record<string, any>) => new Sermon(s))
        .filter((s) => {
          if (this.removeSermonIds.includes(s.id)) return false
          return this.onlyComments ? s.commentCount > 0 : true
        })
      return getListWithPlaceholders(sermons, this.placeholderCount)
    },
    sermonCount(): number | undefined {
      if (this.totalCount === undefined) return undefined
      return this.totalCount - this.removeSermonIds.length
    },
    endOfList(): boolean {
      if (this.optionsReset || this.totalCount === undefined) return false
      return this.sermonsPojo.length >= this.totalCount && !this.next
    },
    categories(): SermonFilterCategories[] {
      return this.filters.map((f) => f.category)
    },
    selected(): number {
      return this.selectedSermonIDs.length
    },
    selectedSermons(): SermonApi[] {
      const sermons = this.sermonsPojo as SermonApi[] | undefined
      if (!sermons) return []
      return this.selectedSermonIDs
        .map((id) =>
          sermons.find((sermon: SermonApi) => sermon.sermonID === id)
        )
        .filter((s) => s !== undefined) as SermonApi[]
    },
  },
  watch: {
    filteredAndSortedOptions: {
      handler(newValue: SermonRequestOptions, oldValue: SermonRequestOptions) {
        if (ValuesMatch(newValue, oldValue)) return
        this.sermonOptionsChanged()
      },
    },
    '$route.query': {
      handler() {
        if (!this.useQs) return
        if (this.selfChangeQs) return
        this.filters = getInitialFilters(this, true, this.defaultFilters)
        this.getInitialDisplayValues()
      },
      immediate: true,
    },
    videoOnly() {
      this.sermonOptionsChanged()
    },
    selectedSermons() {
      this.$emit('selectedSermons', this.selectedSermons)
    },
  },
  mounted() {
    if (!this.async) return
    this.getDetails()
  },
  methods: {
    async setNewFilters(filters: SermonFilterSelection[]) {
      this.filters = filters
      if (!this.useQs) return

      this.selfChangeQs = true
      const query = {} as Record<string, any>
      filters.forEach((f) => {
        query[f.category] = f.value
      })
      await this.$router.replace({ query }).catch()
      await wait(30)
      this.selfChangeQs = false
    },
    async getDetails() {
      await PromiseAll([this.getSermons(), this.getInitialDisplayValues()])
    },
    async getInitialDisplayValues() {
      await PromiseAll([
        this.getInitialBroadcaster(),
        this.getInitialSeries(),
        this.getInitialSpeaker(),
        this.getInitialLanguage(),
        this.getInitialEvents(),
        this.getInitialBook(),
      ])
      this.filters = [...this.filters]
    },
    async getInitialFromStore(
      category: SermonFilterCategories,
      dispatch: string,
      getter: string,
      valueParam: string,
      displayParam: string
    ) {
      const filter = this.getFilter(category)
      if (!filter) return
      await this.$store.dispatch(`filters/${dispatch}`)
      const filters = this.$store.getters[`filters/${getter}`] as any[]
      const match = filters.find((e) => e[valueParam] === filter.value)
      filter.display = match[displayParam]
    },
    async getInitialSpeaker() {
      const filter = this.getFilter(SermonFilterCategories.Speaker)
      if (!filter) return
      const response = await this.$apiClient.getSpeaker(parseInt(filter.value))
      filter.display = new Speaker(response).displayName
    },
    async getInitialSeries() {
      const filter = this.getFilter(SermonFilterCategories.Series)
      if (!filter) return
      const response = await this.$apiClient.getSeries(filter.value)
      filter.display = new Series(response).title
    },
    async getInitialLanguage() {
      await this.getInitialFromStore(
        SermonFilterCategories.Language,
        'getLanguages',
        'languages',
        'languageCode3',
        'localizedName'
      )
    },
    async getInitialEvents() {
      await this.getInitialFromStore(
        SermonFilterCategories.Event,
        'getEvents',
        'events',
        'description',
        'displayEventType'
      )
    },
    async getInitialBook() {
      await this.getInitialFromStore(
        SermonFilterCategories.Book,
        'getBible',
        'bible',
        'osisAbbrev',
        'displayTitle'
      )
    },
    async getInitialBroadcaster() {
      const filter = this.getFilter(SermonFilterCategories.Broadcaster)
      if (!filter) return
      const broadcaster = await this.$apiClient.getBroadcaster(filter.value)
      filter.display = new Broadcaster(broadcaster).displayName
    },
    getFilter(
      category: SermonFilterCategories
    ): SermonFilterSelection | undefined {
      return this.filters.find((f) => f.category === category)
    },
    sermonOptionsChanged() {
      clearTimeout(this.sermonOptionsTimeout)
      this.sermonOptionsTimeout = window.setTimeout(() => {
        this.optionsReset = true
        this.sermonsPojo = []
        this.pageNum = this.sermonOptions.page ?? 1
        this.updateQs()
        this.getSermons()
      }, 20) // ms before sermons are retrieved after a sermon options change
    },
    updateQs() {
      if (!this.useQs) return
      const list = SermonFiltersQsPairs(this.filters)
      const sort = SermonSortQsPair(this.sort)
      if (sort.value !== this.defaultSort) {
        list.push(sort)
      }
      if (this.videoOnly) {
        list.push({ key: SermonQuery.VideoOnly, value: '1' })
      }
      updateQs(this, list)
    },
    async getSermons() {
      if (this.endOfList || this.fetching) return
      this.fetching = true
      const { results, totalCount, next } =
        await this.$apiClient.getFilteredSermonList({
          ...this.filteredAndSortedOptions,
          page: this.pageNum,
        })

      this.totalCount = totalCount
      this.$emit('sermonCount', this.sermonCount)
      this.optionsReset = false
      this.next = next

      this.sermonsPojo = this.sermonsPojo.concat(results)
      this.pageNum++
      this.fetching = false
    },
    isSelected(sermonID: string) {
      return this.selectedSermonIDs.includes(sermonID)
    },
    isSelectable(sermonID: string | undefined) {
      if (!sermonID || this.isSelected(sermonID) || this.selectable === 1)
        return true
      return this.selected < this.selectable
    },
    toggleSermonSelection(sermon: Sermon) {
      const sermonID = sermon.id
      if (!this.isSelectable(sermonID)) return
      let sermonIDs = [...this.selectedSermonIDs]
      const index = this.selectedSermonIDs.indexOf(sermonID)
      if (this.selectable !== 1) {
        if (index > -1) {
          sermonIDs.splice(index, 1)
        } else {
          sermonIDs.push(sermonID)
        }
      } else if (index > -1) {
        sermonIDs = []
      } else {
        sermonIDs = [sermonID]
      }

      this.$emit('selected', sermonIDs)
    },
    filterTitle(filter: SermonFilterSelection): string {
      let value = `${filter.display ?? filter.value}`
      if (filter.category === SermonFilterCategories.Duration) {
        value = DurationFilterTitle(
          this,
          ParseDurationFilter(filter.value)
        ).toString()
      } else if (filter.category === SermonFilterCategories.Media) {
        value = MediaFilterTitle(
          this,
          ParseMediaFilter(filter.value)
        ).toString()
      }
      return `${SermonFilterCategoryTitle(this, filter.category)}: ${value}`
    },
    removeFilter(filter: SermonFilterSelection) {
      let removed = this.filters.filter((f) => f.category !== filter.category)
      if (filter.category === SermonFilterCategories.Book) {
        removed = removed.filter(
          (f) => f.category !== SermonFilterCategories.Chapter
        )
      }
      this.setNewFilters(removed)
    },
    updateSort(sort: SermonSortOptions) {
      this.sort = sort
    },
  },
})
