
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { CustomDropdownProps } from '~/components/_general/CustomDropdown.vue'
import { SaIconsType } from '~/assets/ts/types/icons'
import FancyDropdown, {
  FancyDropdownOption,
} from '~/components/_general/FancyDropdown.vue'
import FancyDropdownPageContents from '~/components/fancyDropdown/PageContents.vue'
import FancyDropdownSearchBar from '~/components/fancyDropdown/SearchBar.vue'
import FancyDropdownBackButton from '~/components/fancyDropdown/BackButton.vue'
import LoadingElement from '~/components/_general/LoadingElement.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import { FuzzySearch } from '~/assets/ts/utils/search'
import { clamp } from '~/assets/ts/utils/math'
import { wait } from '~/assets/ts/utils/misc'

export interface FancyDropdownPage {
  title: TranslateResult
  /**
   * @example
   * category
   * scripture
   * scripture-genesis
   * */
  id: string
  searchable?: boolean
  /** Bool that says the search is being handled by an API instead of local */
  apiSearch?: boolean
  /**
   * https://www.fusejs.io/api/options.html#threshold
   *
   * Specifies how fuzzy the search should be
   *
   * @example
   * Min: 0 (Perfect Match)
   * Max: 1 (Match anything)
   * @default 0.3
   */
  searchThreshold?: number
  childId?: string
  parentId?: string
  options: FancyDropdownOption[]
}

export interface FancyDropdownWithPageEvent {
  id: string
  option: FancyDropdownOption
}

export interface FancyDropdownWithPageSearch {
  page: FancyDropdownPage
  query: string
}

export default Vue.extend({
  name: 'FancyDropdownWithPages',
  components: {
    SaIcon,
    LoadingElement,
    FancyDropdownBackButton,
    FancyDropdownSearchBar,
    FancyDropdownPageContents,
    FancyDropdown,
  },
  props: {
    ...CustomDropdownProps,
    pages: {
      type: Array as PropType<FancyDropdownPage[]>,
      required: true,
    },
    icon: {
      type: String as PropType<SaIconsType>,
      default: '',
    },
    button: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String as PropType<TranslateResult>,
      required: true,
    },
    imageWidth: {
      type: Number,
      default: 32,
    },
    placeholders: {
      type: Number,
      default: 0,
    },
    searching: {
      type: Boolean,
    },
  },
  data() {
    return {
      pageNumber: 0,
      subPageId: '',
      childPageId: '',
      searchTimeout: 0,
      subSearchQuery: '',
      childSearchQuery: '',
    }
  },
  computed: {
    searchTimeoutDuration(): number {
      return 300
    },
    onMain(): boolean {
      return this.pageNumber === 0
    },
    onSub(): boolean {
      return this.pageNumber === 1
    },
    onChild(): boolean {
      return this.pageNumber === 2
    },
    pageContentsClasses(): string {
      return `${this.boxWidth} py-3 absolute transition-position`
    },
    boxWidth(): string {
      return 'w-72'
    },
    boxHeight(): string {
      return 'h-60'
    },
    leftPos(): string {
      return '-left-72 overflow-hidden'
    },
    rightPos(): string {
      return 'left-72 overflow-hidden'
    },
    centerPos(): string {
      return 'left-0'
    },
    mainPagePosition(): string {
      return this.onMain ? this.centerPos : this.leftPos
    },
    subPagePosition(): string {
      if (this.onMain) return this.rightPos
      if (this.onChild) return this.leftPos
      return this.centerPos
    },
    childPagePosition(): string {
      return this.onChild ? this.centerPos : this.rightPos
    },
    mainPage(): FancyDropdownOption[] {
      return this.pages
        .filter((p) => !p.parentId)
        .map((p) => {
          return {
            title: p.title,
            value: p.id,
            disabled: p.options.length < 2,
            parent: true,
          }
        })
    },
    subPage(): FancyDropdownPage | undefined {
      if (!this.subPageId) return undefined
      return this.pages.find((p) => p.id === this.subPageId)
    },
    subOptions(): FancyDropdownOption[] {
      return this.searchedOptions(this.subPage, this.subSearchQuery).map(
        (o) => {
          return {
            ...o,
            parent: !!this.subPage?.childId,
          }
        }
      )
    },
    childPage(): FancyDropdownPage | undefined {
      if (!this.childPageId) return undefined
      return this.pages.find(
        (p) => p.id === this.subPage?.childId && p.parentId === this.childPageId
      )
    },
    childOptions(): FancyDropdownOption[] {
      return this.searchedOptions(this.childPage, this.childSearchQuery)
    },
    dropdown(): Vue {
      return this.$refs.dropdown as Vue
    },
    childSearch(): Vue {
      return this.$refs.childSearch as Vue
    },
    subSearch(): Vue {
      return this.$refs.subSearch as Vue
    },
    childContents(): Vue {
      return this.$refs.childContents as Vue
    },
    subContents(): Vue {
      return this.$refs.subContents as Vue
    },
    mainContents(): Vue | undefined {
      return this.$refs.mainContents as Vue | undefined
    },
  },
  destroyed() {
    this.$off('open', this.openDropdown)
    this.$off('close', this.closeDropdown)
  },
  mounted() {
    this.$on('open', this.openDropdown)
    this.$on('close', this.closeDropdown)
  },
  methods: {
    mainPageClick(value: string) {
      this.subPageId = value
      this.setPage(1)
    },
    childPageClick(value: string) {
      if (!this.childPage || !this.subPage) return
      this.emit([
        {
          id: this.childPage.id,
          option: this.childPage.options.find(
            (o) => o.value === value
          ) as FancyDropdownOption,
        },
        {
          id: this.subPage.id,
          option: this.subPage.options.find(
            (o) => o.value === this.childPageId
          ) as FancyDropdownOption,
        },
      ])
      this.closeDropdown()
    },
    subPageClick(value: string) {
      if (!this.subPage) return
      if (this.subPage.childId) {
        this.childPageId = value
        this.setPage(2)
      } else {
        this.emit([
          {
            id: this.subPage.id,
            option: this.subPage.options.find(
              (o) => o.value === value
            ) as FancyDropdownOption,
          },
        ])
        this.closeDropdown()
      }
    },
    emit(change: FancyDropdownWithPageEvent[]) {
      this.$emit('change', change)
    },
    openDropdown(page = '') {
      this.subPageId = page
      this.dropdown.$emit('open')
    },
    closeDropdown() {
      this.setPage(0)
      this.dropdown.$emit('close')
    },
    closed() {
      this.subPageId = ''
      this.childPageId = ''
      this.setPage(0)
      this.clearSearches()
      this.$emit('closed')
    },
    pageBack() {
      this.setPage(this.pageNumber - 1)
      this.clearSearches()
    },
    setPage(pageNumber: number) {
      this.pageNumber = clamp(pageNumber, 0, 2)
      this.focus()
    },
    async focus() {
      await wait(150) // wait for the animation to finish
      if (this.onChild) {
        if (this.childPage?.searchable) {
          this.childSearch.$emit('focus')
        } else if (this.childPage) {
          this.childContents.$emit('focus')
        }
        this.scrollTop()
      } else if (this.onSub) {
        if (this.subPage?.searchable) {
          this.subSearch.$emit('focus')
        } else if (this.subPage) {
          this.subContents.$emit('focus')
        }
        this.scrollTop()
      } else {
        const index = this.subPageId
          ? this.mainPage.findIndex((p) => p.value === this.subPageId)
          : 0
        this.mainContents?.$emit('focus', index)
      }
    },
    scrollTop() {
      this.dropdown.$emit('scrollTop')
    },
    clearSearches() {
      this.childSearchQuery = ''
      this.subSearchQuery = ''
      this.$emit('clear')
    },
    emitSubSearch() {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = window.setTimeout(() => {
        this.$emit('search', {
          page: this.subPage,
          query: this.subSearchQuery,
        } as FancyDropdownWithPageSearch)
      }, this.searchTimeoutDuration)
    },
    emitChildSearch() {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = window.setTimeout(() => {
        this.$emit('search', {
          page: this.childPage,
          query: this.childSearchQuery,
        } as FancyDropdownWithPageSearch)
      }, this.searchTimeoutDuration)
    },
    searchedOptions(
      page: FancyDropdownPage | undefined,
      query: string
    ): FancyDropdownOption[] {
      if (!page) return []
      const options = page.options
      if (!query || page.apiSearch) return options
      return options.filter((o) => {
        return FuzzySearch(
          [o.title, o.value, o.subtitle],
          query,
          page.searchThreshold
        )
      })
    },
  },
})
