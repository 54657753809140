
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import FocusVisibleElement from '~/components/_general/FocusVisibleElement.vue'
import DropdownArrow from '~/components/_general/DropdownArrow.vue'
import { SaIconsType } from '~/assets/ts/types/icons'
import SaIcon from '~/components/_general/SaIcon.vue'

export interface DropdownOption {
  value: TranslateResult | number | undefined
  display?: TranslateResult
}

export function FilterDropdownOptions(
  searchQuery: string,
  options: DropdownOption[]
): DropdownOption[] {
  const q = searchQuery.toLowerCase()
  return options.filter((d) => {
    if (!d.value) return false
    if (d.value.toString().toLowerCase().includes(q)) return true
    if (!d.display) return false
    return d.display.toString().toLowerCase().includes(q)
  })
}
export const DropdownNullDisplay = '-----'

export function NullableDropdownOptions(
  options: DropdownOption[],
  nullValue: any = ''
) {
  return [{ value: nullValue, display: DropdownNullDisplay }, ...options]
}

export default Vue.extend({
  name: 'Dropdown',
  components: { SaIcon, DropdownArrow, FocusVisibleElement },
  model: {
    event: 'change',
  },
  props: {
    value: {
      type: [Number, String, null] as PropType<
        TranslateResult | number | undefined
      >,
      default: undefined,
    },
    options: {
      type: Array as PropType<DropdownOption[]>,
      default: [] as DropdownOption[],
    },
    disabled: {
      type: Boolean,
    },
    autofocus: {
      type: Boolean,
    },
    rounded: {
      type: Boolean,
    },
    icon: {
      type: String as PropType<SaIconsType>,
      default: undefined,
    },
  },
  data() {
    return {
      focusing: false,
    }
  },
  methods: {
    DropdownNullDisplay() {
      return DropdownNullDisplay
    },
    change(event: Event) {
      this.$emit('change', (event.target as HTMLSelectElement).value)
    },
    selected(
      value1: TranslateResult | number | undefined,
      value2: TranslateResult | number | undefined
    ): boolean {
      if (value1 === value2) return true
      if (value1 && value2) {
        return value1.toString() === value2.toString()
      }
      return false
    },
  },
})
