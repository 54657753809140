
import Vue from 'vue'

export default Vue.extend({
  name: 'ToggleElement',
  props: {
    checked: {
      type: Boolean,
    },
    activeColor: {
      type: String,
      default: 'text-theme-blue',
      validator(value: string) {
        return value.includes('text-')
      },
    },
    /** background color of the page */
    bgColor: {
      type: String,
      default: 'bg-theme',
      validator(value: string) {
        return value.includes('bg-')
      },
    },
    /** Outline color that matches bgColor */
    bgOutlineColor: {
      type: String,
      default: 'outline-bg-theme',
    },
  },
})
