
import Vue, { PropType } from 'vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import ToggleElement from '~/components/_general/ToggleElement.vue'
import { SaIconsType } from '~/assets/ts/types/icons'

export default Vue.extend({
  name: 'ToggleButton',
  components: { ToggleElement, InlineIcon },
  model: {
    prop: 'checked',
    event: 'checked',
  },
  props: {
    icon: {
      type: String as PropType<SaIconsType>,
      default: undefined,
    },
    checked: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    disabledColor: {
      type: String,
      default: 'text-gray-metadata',
    },
    activeColor: {
      type: String,
      default: undefined,
      validator(value: string | undefined) {
        return value === undefined || value.includes('text-')
      },
    },
    bgColor: {
      type: String,
      default: undefined,
      validator(value: string | undefined) {
        return value === undefined || value.includes('bg-')
      },
    },
  },
  methods: {
    click() {
      if (this.disabled) return
      this.$emit('checked', !this.checked)
      this.$emit('click')
    },
  },
})
