import { render, staticRenderFns } from "./ToggleElement.vue?vue&type=template&id=52931468&scoped=true"
import script from "./ToggleElement.vue?vue&type=script&lang=ts"
export * from "./ToggleElement.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52931468",
  null
  
)

export default component.exports