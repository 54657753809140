import Fuse from 'fuse.js'

// https://www.fusejs.io/api/options.html#threshold
export function FuzzySearch(list: any[], query: string, threshold = 0.3) {
  const fuse = new Fuse(list, {
    threshold,
  })
  return !!fuse.search(query).length
}

// https://www.fusejs.io/api/options.html#threshold
/** Returns a value 0-1 with 1 being a perfect match and 0 being no match */
export function FuzzySearchRank(
  list: any[],
  query: string,
  threshold = 0.3,
  minChars = 3
): number {
  const fuse = new Fuse(list, {
    threshold,
    includeScore: true,
    minMatchCharLength: minChars,
  })
  const scores = fuse.search(query).map((f) => f.score ?? 0)
  return scores.length ? 1 - Math.min(...scores) : 0
}
